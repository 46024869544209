import { IEnvironment, WindowWithEnvironment } from '@dsg/shared/utils/environment';
import { FeatureFlags } from '@dsg/shared/utils/feature-flagging';

declare let window: WindowWithEnvironment;

const _environment: IEnvironment<Partial<FeatureFlags>> = {
  authenticationConfiguration: {
    firebaseConfiguration: {
      firebase: {
        //apiKey: 'AIzaSyAKsXCEYvBVgfTITzK6wJE01RznBcrMfD8',
        apiKey: 'AIzaSyBQVpaC6ym3tAZFUrzLkk6u5Rz9jelgi4w',
        authDomain: 'egen-cwb03-dev.firebaseapp.com',
      },
      providerId: 'oidc.public',
      tenantId: 'public-portal-at7on',
    },
    sessionExpiration: {
      idleTimeSeconds: 60 * 30,
      sessionTimeSeconds: 60 * 60 * 18,
    },
  },
  featureFlags: {},
  googlePlacesApiConfiguration: {
    googleApiKey: 'AIzaSyDZlyDFToy76KtEiHduNVjQKfyeRZHQXVM',
  },
  httpConfiguration: {
    baseUrl: 'https://api-dev.cwb03.mta.demo.springmlsandbox.com',
  },
};

export const environment: IEnvironment = {
  ..._environment,
  ...window.environment, // must be last
};
